<template>
    <a :href="url"
    target="_blank"
    >
      <img class="img-help" :src="require('@/assets/icons/Bt-interrogation-inactif.svg')">
    </a>
</template>

<script>
export default {
  name: 'BtHelp',
  props: {
    wfclass: {
      type: String,
      default: null
    }
  },
  computed: {
    url: function () {
      return ('/help/' + this.wfclass)
    }
  }
}
</script>
<style>
.img-help {
  margin-top:8px;
  margin-right:8px;
  width: 35px;
}
.img-help:hover {
  content: url("../../../assets/icons/Bt-interrogation-actif.svg");
}
.modal-card-head {
  background: linear-gradient(to right, #564af9, #1db4ce);
}
.modal-card-body {
  background: #f8f8f8
}
.modal-card-title {
  color: white;
  font-size: 25px;
}
</style>
